<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="model.CompanyId"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['CompanyId']"
                      :hint="$te(`${parentComponentName}.Form.Fields.CompanyId.Hint`) ? $t(`${parentComponentName}.Form.Fields.CompanyId.Hint`) : ''"
                      :items="companies"
                      :label="$te(`${parentComponentName}.Form.Fields.CompanyId.Label`) ? $t(`${parentComponentName}.Form.Fields.CompanyId.Label`) : 'CompanyId'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('CompanyId')"
                      item-text="Name"
                      item-value="Id"
                      @focusin="resetServerErrorMessage('CompanyId')"
                    >
                      <template #item="{on, attrs, item}">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="item.Name" />
                            <v-list-item-subtitle v-text="item.Description" />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Name"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Name']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Name.Hint`) ? $t(`${parentComponentName}.Form.Fields.Name.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Name.Label`) ? $t(`${parentComponentName}.Form.Fields.Name.Label`) : 'Name'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Name')"
                      @focusin="resetServerErrorMessage('Name')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Description"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Description']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Description.Hint`) ? $t(`${parentComponentName}.Form.Fields.Description.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Description.Label`) ? $t(`${parentComponentName}.Form.Fields.Description.Label`) : 'Description'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Description')"
                      @focusin="resetServerErrorMessage('Description')"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="3">
                    <select-date
                      v-model="model.StartedAt"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :label="$te(`${parentComponentName}.Form.Fields.StartedAt.Label`) ? $t(`${parentComponentName}.Form.Fields.StartedAt.Label`) : 'From'"
                      :rules="model.validator.vuetifyFormFieldRules('StartedAt')"
                    />
                  </v-col>
                  <v-col cols="3">
                    <select-time
                      v-model="model.StartedTimeAt"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :label="$te(`${parentComponentName}.Form.Fields.StartedTimeAt.Label`) ? $t(`${parentComponentName}.Form.Fields.StartedTimeAt.Label`) : 'From'"
                      :rules="model.validator.vuetifyFormFieldRules('StartedTimeAt')"
                    />
                  </v-col>

                  <v-col cols="3">
                    <select-date
                      v-model="model.EndedAt"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :label="$te(`${parentComponentName}.Form.Fields.EndedAt.Label`) ? $t(`${parentComponentName}.Form.Fields.EndedAt.Label`) : 'To'"
                      :rules="model.validator.vuetifyFormFieldRules('EndedAt')"
                    />
                  </v-col>
                  <v-col cols="3">
                    <select-time
                      v-model="model.EndedTimeAt"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :label="$te(`${parentComponentName}.Form.Fields.EndedTimeAt.Label`) ? $t(`${parentComponentName}.Form.Fields.EndedTimeAt.Label`) : 'To'"
                      :rules="model.validator.vuetifyFormFieldRules('EndedTimeAt')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-toolbar
                      :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
                      dense
                      flat
                    >
                      <v-toolbar-title class="subtitle-2">
                        {{
                          $te(`${ parentComponentName }.Form.Fields.Committee.Label`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Label`) : 'Committee'
                        }}
                      </v-toolbar-title>

                      <v-spacer />

                      <v-btn
                        v-if="!isReadOnly"
                        color="primary"
                        depressed
                        fab
                        x-small
                        @click="addCommitteeMember()"
                      >
                        <v-icon>
                          mdi mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-toolbar>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-simple-table
                      v-if="model.Committee.length"
                      dense
                    >
                      <template #default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{
                                $te(`${ parentComponentName }.Form.Fields.Committee.Title`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Title`) : 'Title'
                              }}
                            </th>

                            <th class="text-left">
                              {{
                                $te(`${ parentComponentName }.Form.Fields.Committee.Name`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Name`) : 'Name'
                              }}
                            </th>

                            <th class="text-left">
                              {{
                                $te(`${ parentComponentName }.Form.Fields.Committee.Email`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Email`) : 'Email'
                              }}
                            </th>

                            <th class="text-left">
                              {{
                                $te(`${ parentComponentName }.Form.Fields.Committee.Phone`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Phone`) : 'Phone'
                              }}
                            </th>

                            <th class="text-right">
                              <template v-if="!!model.VotersPdf">
                                <v-tooltip
                                  :open-delay="650"
                                  color="purple"
                                  left
                                >
                                  <template #activator="{on}">
                                    <v-btn
                                      :href="model.VotersPdf"
                                      class="ma-1 white--text"
                                      color="purple"
                                      depressed
                                      fab
                                      target="_blank"
                                      x-small
                                      v-on="on"
                                    >
                                      <v-icon>mdi-file-pdf-box</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Κατέβασμα PDF Ψηφισάντων</span>
                                </v-tooltip>
                              </template>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(item, index) in model.Committee">
                            <tr :key="index">
                              <td class="text-no-wrap">
                                <v-text-field
                                  v-if="!isReadOnly"
                                  v-model="item.Title"
                                  :clearable="!isReadOnly"
                                  :disabled="isReadOnly"
                                  :placeholder="$te(`${ parentComponentName }.Form.Fields.Committee.Title`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Title`) : 'Title'"
                                  :readonly="isReadOnly"
                                  :rules="model.validator.vuetifyFormFieldRules(`Committee.${index}.Title`, `Committee.*.Title`)"
                                  class="mt-4"
                                  dense
                                  filled
                                  flat
                                  single-line
                                />
                                <span
                                  v-else
                                  class="grey--text"
                                >{{ item.Title }}</span>
                              </td>

                              <td class="text-no-wrap">
                                <v-text-field
                                  v-if="!isReadOnly"
                                  v-model="item.Name"
                                  :clearable="!isReadOnly"
                                  :disabled="isReadOnly"
                                  :placeholder="$te(`${ parentComponentName }.Form.Fields.Committee.Name`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Name`) : 'Name'"
                                  :readonly="isReadOnly"
                                  :rules="model.validator.vuetifyFormFieldRules(`Committee.${index}.Name`, `Committee.*.Name`)"
                                  class="mt-4"
                                  dense
                                  filled
                                  flat
                                  single-line
                                />
                                <span
                                  v-else
                                  class="grey--text"
                                >{{ item.Name }}</span>
                              </td>

                              <td class="text-no-wrap">
                                <v-text-field
                                  v-if="!isReadOnly"
                                  v-model="item.Email"
                                  :clearable="!isReadOnly"
                                  :disabled="isReadOnly"
                                  :placeholder="$te(`${ parentComponentName }.Form.Fields.Committee.Email`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Email`) : 'Email'"
                                  :readonly="isReadOnly"
                                  :rules="model.validator.vuetifyFormFieldRules(`Committee.${index}.Email`, `Committee.*.Email`)"
                                  class="mt-4"
                                  dense
                                  filled
                                  flat
                                  single-line
                                />
                                <span
                                  v-else
                                  class="grey--text"
                                >{{ item.Email }}</span>
                              </td>

                              <td class="text-no-wrap">
                                <v-text-field
                                  v-if="!isReadOnly"
                                  v-model="item.Phone"
                                  v-mask="'##########'"
                                  :clearable="!isReadOnly"
                                  :disabled="isReadOnly"
                                  :placeholder="$te(`${ parentComponentName }.Form.Fields.Committee.Phone`) ? $t(`${ parentComponentName }.Form.Fields.Committee.Phone`) : 'Phone'"
                                  :readonly="isReadOnly"
                                  :rules="model.validator.vuetifyFormFieldRules(`Committee.${index}.Phone`, `Committee.*.Phone`)"
                                  class="mt-4"
                                  dense
                                  filled
                                  flat
                                  single-line
                                />
                                <span
                                  v-else
                                  class="grey--text"
                                >{{ item.Phone }}</span>
                              </td>

                              <td class="text-right text-no-wrap">
                                <template v-if="!isReadOnly">
                                  <v-tooltip
                                    :open-delay="650"
                                    color="red"
                                    left
                                  >
                                    <template #activator="{on}">
                                      <v-btn
                                        color="red"
                                        icon
                                        small
                                        v-on="on"
                                        @click.stop="removeCommitteeMember(index)"
                                      >
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-text="$t('Common.Button.Delete')" />
                                  </v-tooltip>
                                </template>

                                <template v-if="!!model.VotersPdf">
                                  <v-tooltip
                                    :open-delay="650"
                                    color="indigo"
                                    left
                                  >
                                    <template #activator="{on}">
                                      <v-btn
                                        :disabled="item.LoadingEmail || !model.VotersPdf"
                                        :loading="item.LoadingEmail"
                                        class="ma-1 white--text"
                                        color="indigo"
                                        depressed
                                        fab
                                        x-small
                                        @click="sendVotersPdfToCommitteeMember(item)"
                                        v-on="on"
                                      >
                                        <v-icon>mdi-email</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Αποστολή PDF Ψηφισάντων</span>
                                  </v-tooltip>
                                </template>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <div class="mx-6 text-right">
                      <label class="mr-4">Αποστολή Κωδικών</label>

                      <v-switch
                        v-model="model.CommitteeSms"
                        :disabled="isReadOnly || !model.Sms"
                        :readonly="isReadOnly || !model.Sms"
                        class="d-inline-block mr-4"
                        color="success"
                        dense
                        hide-details
                        inset
                        label="SMS"
                      />
                      <v-switch
                        v-model="model.CommitteeEmail"
                        :disabled="isReadOnly || !model.Email"
                        :readonly="isReadOnly || !model.Email"
                        class="d-inline-block"
                        color="success"
                        dense
                        hide-details
                        inset
                        label="Email"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-toolbar
                      :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
                      dense
                      flat
                    >
                      <v-toolbar-title class="subtitle-2">
                        {{ $t(`${ parentComponentName }.Form.Fields.Categories.Label`) }}
                      </v-toolbar-title>

                      <v-spacer />

                      <v-menu
                        bottom
                        transition="slide-y-transition"
                      >
                        <template #activator="{on, attrs}">
                          <v-btn
                            v-if="!isReadOnly"
                            :disabled="!availableCategories.length"
                            color="primary"
                            depressed
                            fab
                            v-bind="attrs"
                            x-small
                            v-on="on"
                          >
                            <v-icon>
                              mdi mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <template v-for="(category, categoryIndex) in availableCategories">
                            <v-list-item
                              :key="`list-item-category-${categoryIndex}`"
                              two-line
                              @click="addElectionCategory(category)"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{ category.Name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ category.Description }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </v-toolbar>

                    <v-messages
                      v-model="model.errors.Categories"
                      class="error--text mt-2"
                    />
                  </v-col>
                </v-row>

                <template v-for="(category, index) in model.Categories">
                  <v-row
                    :key="`election-category-${index}`"
                    dense
                  >
                    <v-col cols="12">
                      <v-subheader class="font-weight-bold">
                        {{ category.Name }}

                        <v-spacer />

                        <div v-if="!isReadOnly">
                          <v-tooltip
                            :open-delay="650"
                            color="red"
                            left
                          >
                            <template #activator="{on}">
                              <v-btn
                                color="red"
                                icon
                                small
                                v-on="on"
                                @click.stop="removeElectionCategory(index)"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="$t('Common.Button.Delete')" />
                          </v-tooltip>
                        </div>
                      </v-subheader>
                    </v-col>

                    <v-col cols="12">
                      <v-container
                        class="py-0"
                        fluid
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="category.Candidates"
                              :clearable="!isReadOnly"
                              :disabled="isReadOnly"
                              :error-messages="serverErrorMessages[`Categories.${index}.Candidates`]"
                              :item-text="(item) => `${item.Uid} - ${item.LastName} ${item.FirstName} ${item.FathersName ? `του ${item.FathersName}` : ''} ${item.Corps ? `(${item.Corps})` : '' }`"
                              :items="members"
                              :label="$t(`${ parentComponentName }.Form.Fields.Candidates.Label`)"
                              :readonly="isReadOnly"
                              :rules="model.validator.vuetifyFormFieldRules(`Categories.${index}.Candidates`)"
                              chips
                              deletable-chips
                              item-value="Id"
                              multiple
                              return-object
                              small-chips
                              @focusin="resetServerErrorMessage(`Categories.${index}.Candidates`)"
                            >
                              <template #item="{on, attrs, item}">
                                <v-list-item
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-list-item-content>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-text="`${item.LastName} ${item.FirstName} ${item.FathersName ? `του ${item.FathersName}` : ''} ${item.Corps ? `(${item.Corps})` : '' }`"
                                      />
                                      <v-list-item-subtitle v-text="item.Uid" />
                                    </v-list-item-content>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="category.MaxVotes"
                              :disabled="isReadOnly"
                              :error-messages="serverErrorMessages[`Categories.${index}.MaxVotes`]"
                              :label="$t(`${ parentComponentName }.Form.Fields.MaxVotes.Label`)"
                              :readonly="isReadOnly"
                              :rules="model.validator.vuetifyFormFieldRules(`Categories.${index}.MaxVotes`)"
                              min="0"
                              persistent-hint
                              step="1"
                              type="number"
                              @focusin="resetServerErrorMessage(`Categories.${index}.MaxVotes`)"
                            />
                          </v-col>

                          <v-col cols="12">
                            <v-divider class="mb-4" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </template>

                <v-row>
                  <v-col cols="3">
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.OfflineVoter.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.OfflineVoter"
                      :disabled="isReadOnly"
                      :label="model.OfflineVoter ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('OfflineVoter')"
                      class="mt-1"
                      color="orange"
                      inset
                      @focusin="resetServerErrorMessage('OfflineVoter')"
                    />
                  </v-col>

                  <v-col cols="3">
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.DashboardStats.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.DashboardStats"
                      :disabled="isReadOnly"
                      :label="model.DashboardStats ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('DashboardStats')"
                      class="mt-1"
                      color="light-blue"
                      inset
                      @focusin="resetServerErrorMessage('DashboardStats')"
                    />
                  </v-col>

                  <v-col cols="3">
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.Email.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.Email"
                      :disabled="isReadOnly"
                      :label="model.Email ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Email')"
                      class="mt-1"
                      color="success"
                      inset
                      @focusin="resetServerErrorMessage('Email')"
                    />
                  </v-col>

                  <v-col cols="3">
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.Sms.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.Sms"
                      :disabled="isReadOnly"
                      :label="model.Sms ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Sms')"
                      class="mt-1"
                      color="success"
                      inset
                      @focusin="resetServerErrorMessage('Sms')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div class="grey--text text--darken-3">
                      <h3 class="pl-1 pb-2 v-label--active font-weight-regular">
                        {{ $t('Elections.Form.Fields.NotesEmail.Label') }}
                      </h3>

                      <editor-menu-bar
                        v-slot="{commands, isActive}"
                        :editor="editorEmail"
                      >
                        <v-toolbar
                          v-show="!isReadOnly"
                          class="mb-1"
                          dense
                          elevation="2"
                          tile
                        >
                          <v-toolbar-items>
                            <v-btn
                              :class="{'is-active': isActive.bold()}"
                              small
                              text
                              @click="commands.bold"
                            >
                              <v-icon>mdi-format-bold</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.italic()}"
                              small
                              text
                              @click="commands.italic"
                            >
                              <v-icon>mdi-format-italic</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.strike()}"
                              small
                              text
                              @click="commands.strike"
                            >
                              <v-icon>mdi-format-strikethrough</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.underline()}"
                              small
                              text
                              @click="commands.underline"
                            >
                              <v-icon>mdi-format-underline</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              :class="{'is-active': isActive.heading({level: 1})}"
                              small
                              text
                              @click="commands.heading({level: 1})"
                            >
                              <v-icon>mdi-format-header-1</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.heading({level: 2})}"
                              small
                              text
                              @click="commands.heading({level: 2})"
                            >
                              <v-icon>mdi-format-header-2</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.heading({level: 3})}"
                              small
                              text
                              @click="commands.heading({level: 3})"
                            >
                              <v-icon>mdi-format-header-3</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              :class="{'is-active': isActive.bullet_list()}"
                              small
                              text
                              @click="commands.bullet_list"
                            >
                              <v-icon>mdi-format-list-bulleted</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.ordered_list()}"
                              small
                              text
                              @click="commands.ordered_list"
                            >
                              <v-icon>mdi-format-list-numbered</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              :class="{'is-active': isActive.code_block()}"
                              small
                              text
                              @click="commands.code_block"
                            >
                              <v-icon>mdi-code-tags</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              small
                              text
                              @click="commands.horizontal_rule"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              small
                              text
                              @click="commands.undo"
                            >
                              <v-icon>mdi-undo</v-icon>
                            </v-btn>

                            <v-btn
                              small
                              text
                              @click="commands.redo"
                            >
                              <v-icon>mdi-redo</v-icon>
                            </v-btn>

                            <v-divider vertical />
                          </v-toolbar-items>
                        </v-toolbar>
                      </editor-menu-bar>

                      <editor-content
                        :class="[{'editor-focus': !isReadOnly, 'editor-disabled': isReadOnly}]"
                        :editor="editorEmail"
                        class="editor__content"
                      />
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="grey--text text--darken-3">
                      <h3 class="pl-1 pb-2 v-label--active font-weight-regular">
                        {{ $t('Elections.Form.Fields.NotesWebsite.Label') }}
                      </h3>

                      <editor-menu-bar
                        v-slot="{commands, isActive}"
                        :editor="editorWebsite"
                      >
                        <v-toolbar
                          v-show="!isReadOnly"
                          class="mb-1"
                          dense
                          elevation="2"
                          tile
                        >
                          <v-toolbar-items>
                            <v-btn
                              :class="{'is-active': isActive.bold()}"
                              small
                              text
                              @click="commands.bold"
                            >
                              <v-icon>mdi-format-bold</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.italic()}"
                              small
                              text
                              @click="commands.italic"
                            >
                              <v-icon>mdi-format-italic</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.strike()}"
                              small
                              text
                              @click="commands.strike"
                            >
                              <v-icon>mdi-format-strikethrough</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.underline()}"
                              small
                              text
                              @click="commands.underline"
                            >
                              <v-icon>mdi-format-underline</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              :class="{'is-active': isActive.heading({level: 1})}"
                              small
                              text
                              @click="commands.heading({level: 1})"
                            >
                              <v-icon>mdi-format-header-1</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.heading({level: 2})}"
                              small
                              text
                              @click="commands.heading({level: 2})"
                            >
                              <v-icon>mdi-format-header-2</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.heading({level: 3})}"
                              small
                              text
                              @click="commands.heading({level: 3})"
                            >
                              <v-icon>mdi-format-header-3</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              :class="{'is-active': isActive.bullet_list()}"
                              small
                              text
                              @click="commands.bullet_list"
                            >
                              <v-icon>mdi-format-list-bulleted</v-icon>
                            </v-btn>

                            <v-btn
                              :class="{'is-active': isActive.ordered_list()}"
                              small
                              text
                              @click="commands.ordered_list"
                            >
                              <v-icon>mdi-format-list-numbered</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              :class="{'is-active': isActive.code_block()}"
                              small
                              text
                              @click="commands.code_block"
                            >
                              <v-icon>mdi-code-tags</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              small
                              text
                              @click="commands.horizontal_rule"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>

                            <v-divider vertical />

                            <v-btn
                              small
                              text
                              @click="commands.undo"
                            >
                              <v-icon>mdi-undo</v-icon>
                            </v-btn>

                            <v-btn
                              small
                              text
                              @click="commands.redo"
                            >
                              <v-icon>mdi-redo</v-icon>
                            </v-btn>

                            <v-divider vertical />
                          </v-toolbar-items>
                        </v-toolbar>
                      </editor-menu-bar>

                      <editor-content
                        :class="[{'editor-focus': !isReadOnly, 'editor-disabled': isReadOnly}]"
                        :editor="editorWebsite"
                        class="editor__content"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils }                                                                                                                                         from '@/lib/utils'
import { isBoolean }                                                                                                                                          from '@/lib/utils/type'
import dialogVisible
  from '@/mixins/dialog/dialogVisible'
import dialogData
  from '@/mixins/dialog/dialogData'
import ElectionModel
  from '@/api/models/election/ElectionModel'
import SelectDate
  from '@/theme/default/form/SelectDate.vue'
import SelectTime
  from '@/theme/default/form/SelectTime.vue'
import CommitteeResource
  from '@/api/resources/election/CommitteeResource'
import { Editor, EditorContent, EditorMenuBar }                                                                                                               from 'tiptap'
import { Blockquote, Bold, BulletList, Code, CodeBlock, HardBreak, Heading, History, HorizontalRule, Italic, Link, ListItem, OrderedList, Strike, Underline } from 'tiptap-extensions'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'ElectionEditDialog',
  components: {
    EditorContent,
    EditorMenuBar,
    SelectTime,
    SelectDate,
    DataCard
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    companies: {
      type   : Array,
      default: () => []
    },
    members: {
      type   : Array,
      default: () => []
    },
    categories: {
      type   : Array,
      default: () => []
    }
  },
  enums    : {},
  dataModel: ElectionModel,
  data () {
    return {
      editorWebsite: null,
      editorEmail  : null,
      isFormSaving : false
    }
  },
  computed: {
    availableCategories () {
      return this.categories?.filter(c => !this.model.Categories.find(mc => mc.Id === c.Id)) || []
    }
  },
  watch: {
    model (newVal) {
      if (newVal) {
        this.initEditors()
      } else {
        this.clearEditors()
      }
    },
    'model.Email': function (newVal) {
      if (!newVal && this.model) this.model.CommitteeEmail = false
    },

    'model.Sms': function (newVal) {
      if (!newVal && this.model) this.model.CommitteeSms = false
    },

    'model.Categories': function () {
      this.model && this.model.validateField('Categories')
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    ...{ isBoolean },

    addElectionCategory (category) {
      if (!category) return

      this.model.Categories.push({
        Id        : category.Id,
        Name      : category.Name,
        MaxVotes  : 0,
        Candidates: []
      })
    },
    removeElectionCategory (index) {
      this.model.Categories.splice(index, 1)
    },

    addCommitteeMember () {
      this.model.Committee.push(
          new CommitteeResource({
            name : '',
            email: ''
          })
      )
    },
    removeCommitteeMember (index) {
      this.model.Committee.splice(index, 1)
    },

    sendVotersPdfToCommitteeMember (member) {
      this.$root.$confirm(
          'Αποστολή PDF Ψηφισάντων',
          'Επιβεβαίωση Αποστολής PDF Ψηφισάντων',
          `Είστε σίγουροι ότι θέλετε στείλετε το PDF Ψηφισάντων στο μέλος της εφορευτικής επιτροπής: ${ member.Name } (${ member.Title }) των εκλογών: ${ this.model.Name } της ένωσης ${ this.model.Company };`,
          {
            color        : 'indigo',
            icon         : 'mdi-alert',
            validate     : false,
            coloredBorder: false,
            dark         : true,
            button       : {
              no : { title: this.$t('Common.Button.No') },
              yes: { title: this.$t('Common.Button.Yes') }
            }
          }
      ).then(response => {
        if (response) {
          this.$set(member, 'LoadingEmail', true)

          this.model.SendVotersPdf(member.Id).finally(() => {
            this.$set(member, 'LoadingEmail', false)
          })
        }
      })
    },

    clearEditors () {
      if (this.editorEmail) this.editorEmail.destroy()
      if (this.editorWebsite) this.editorWebsite.destroy()
    },

    initEditors () {
      this.clearEditors()

      this.editorEmail = new Editor({
        autoFocus : false,
        editable  : !this.isReadOnly,
        content   : this.model.NotesEmail || '',
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Code(),
          new CodeBlock(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        onUpdate: ({ getHTML }) => {
          if (!this.isReadOnly) {
            this.model.NotesEmail = getHTML()
            if (this.validator) {
              this.validator.validateField('NotesEmail')
            }
          }
        }
      })

      this.editorWebsite = new Editor({
        autoFocus : false,
        editable  : !this.isReadOnly,
        content   : this.model.Notes || '',
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Code(),
          new CodeBlock(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        onUpdate: ({ getHTML }) => {
          if (!this.isReadOnly) {
            this.model.Notes = getHTML()
            if (this.validator) {
              this.validator.validateField('Notes')
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .ProseMirror:focus {
  outline : none;
}

/deep/ .editor__content {
  padding          : 12px;
  border           : 2px solid #ff9800;
  border-radius    : 4px;
  background-color : #ffd180;
}

/deep/ .editor-disabled {
  color            : #888888;
  border           : 2px solid #383838;
  background-color : #494949;
}

/deep/ .editor__content p {
  margin-bottom : 0;
}

</style>
