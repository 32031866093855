<template>
  <div class="pa-1 pa-sm-6">
    <v-img
      class="ma-2 ma-sm-6"
      contain
      height="80"
      position="center"
      src="/img/logo/logo-dark.png"
    />

    <v-card-title
      :class="{'h2': $vuetify.breakpoint.smAndUp, 'h3 font-weight-bold': $vuetify.breakpoint.xs,}"
      class="justify-center mb-5"
    >
      {{ $t('Users.TwoFA.Title') }}
    </v-card-title>

    <v-card-subtitle class="h4 text-left my-3">
      {{ $t('Users.TwoFA.SubTitle') }}
    </v-card-subtitle>

    <v-card-text>
      <v-form
        ref="form"
        v-model="isFormValid"
        @submit.prevent
      >
        <v-text-field
          v-model="model.Pin"
          v-mask="'######'"
          :disabled="isSubmittingData"
          :label="$t('Users.TwoFA.Pin')"
          :placeholder="$t('Users.TwoFA.Pin')"
          :rules="model.validator.vuetifyFormFieldRules('Pin')"
          outlined
          type="password"
        >
          <template
            v-if="model.Expire > 0"
            #append
          >
            <countdown-timer
              :time-limit="model.Expire"
              style="position: absolute; top: 6px; right: 8px;"
              @completed="onTimerCompleted"
            />
          </template>
        </v-text-field>
      </v-form>

      <v-alert
        v-if="errorMessage"
        border="left"
        class="text-center"
        color="error"
        dense
        text
        type="error"
      >
        {{ errorMessage }}
      </v-alert>
    </v-card-text>

    <v-card-actions class="d-block text-right px-4">
      <v-btn
        :disabled="isSubmittingData"
        :large="$vuetify.breakpoint.smOnly"
        :to="{name: 'Login'}"
        :x-large="$vuetify.breakpoint.mdAndUp"
        class="px-4 px-sm-8 px-md-12"
        depressed
        plain
        v-text="$t('Common.Button.Cancel')"
      />

      <v-btn
        :disabled="!isFormValid || isSubmittingData"
        :large="$vuetify.breakpoint.smOnly"
        :loading="isSubmittingData"
        :x-large="$vuetify.breakpoint.mdAndUp"
        class="px-4 px-sm-8 px-md-12"
        color="brand-primary"
        depressed
        @click="onSubmitClick"
        v-text="$t('Common.Button.Continue')"
      />
    </v-card-actions>
  </div>
</template>

<script>
import i18nRouteMeta    from '@/mixins/i18n/i18nRouteMeta'
import TwoFAModel       from '@/api/models/user/TwoFAModel'
import { toPascalCase } from '@/lib/utils/string'
import ApiResponseEnum  from '@/api/enums/ApiResponseEnum'
import CountdownTimer   from '@/theme/default/components/widgets/CountdownTimer'

export default {
  name      : 'TwoFALogin',
  components: { CountdownTimer },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  data () {
    return {
      isFormValid     : false,
      isSubmittingData: false,
      errorMessage    : '',
      componentData   : new TwoFAModel()
    }
  },
  computed: {
    model: {
      get () {
        return this.componentData
      },
      set (val) {
        this.componentData = val
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    window.addEventListener('keydown', this.onKeyDown)
    if (this.$refs.form) this.$refs.form.reset()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  destroyed () {},
  methods: {
    onTimerCompleted () {
      this.$router.replace({ name: 'Login' })
    },

    onKeyDown (e) {
      if (e.keyCode === 13 && !this.isSubmittingData) {
        e.preventDefault()
        this.onSubmitClick()
      }
    },

    onSubmitClick () {
      if (this.validateForm()) {
        this.isSubmittingData = true
        this.errorMessage = ''
        this.serverErrorMessages = {}

        this.model.TwoFALogin()
          .then((response) => {
            const status = response.status
            const code = response.data?.code
            // eslint-disable-next-line no-unused-vars
            const message = response?.data?.message
            const success = response?.data?.success
            const data = response.data?.data

            if (status === ApiResponseEnum.SUCCESS && code === ApiResponseEnum.SUCCESS && success && data && data.token && data.user) {
              this.onLoginSuccess(response, data)
            } else {
              this.errorMessage = message || this.$t('Common.Error.Generic')
            }
          })
          .catch((e) => {
            const status = e.response.status
            const errors = e.response?.data?.errors || {}
            const message = e.response?.data?.message

            if (status === ApiResponseEnum.VALIDATOR_ERROR) {
              this.errorMessage = message || this.$t('Common.Error.Generic')
            } else if (status === ApiResponseEnum.GENERIC_ERROR) {
              if (errors.hasOwnProperty('token') || errors.hasOwnProperty('Token')) { this.errorMessage = this.$t('Common.Error.Generic') }

              for (const key in errors) {
                if (errors.hasOwnProperty(key)) this.serverErrorMessages[toPascalCase(key)] = errors[key]
              }
            } else {
              this.errorMessage = message || this.$t('Common.Error.Generic')
            }
          })
          .finally(() => {
            if (this.$refs.form) this.$refs.form.reset()
            this.isSubmittingData = false
          })
      }
    },

    validateForm (deep = false) {
      this.isFormValid = true
      if (this.$refs?.form) this.isFormValid = this.$refs.form.validate()
      if (this.model?.validator) {
        this.isFormValid = (deep ? this.model.validate() : this.model.validator.validate())
        // eslint-disable-next-line no-console
        console.log(this.model.validator.constructor.name, this.model.errors)
      }
      return this.isFormValid
    }
  }
}

</script>

<style scoped>
/* Change Autocomplete styles in Chrome, Safari & Firefox*/
@-webkit-keyframes autofill {
  0%, 100% {
    color      : #FFFFFF;
    background : transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay     : 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name      : autofill;
  -webkit-animation-fill-mode : both;
}
</style>
